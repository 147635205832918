<template>
<div>
    <!--v-app-bar dark absolute app text color="primary" style="width: auto; margin-left: 0px"-->
    <v-app-bar dark absolute app text color="primary" 
        style="margin-left: 0px; white-space: nowrap; overflow-x: auto;"
        :class="{'scrollable-app-bar': !$vuetify.breakpoint.mdAndUp}"
        >
        <v-btn fab text left x-small v-if="!drawer" v-tippy title="Mostrar Barra Lateral" @click="setDrawer(!drawer)">
            <v-icon small>mdi-arrow-expand-right</v-icon>
        </v-btn>

        <!-- utilerias -->
        <v-menu text nav bottom offset-y origin="center center" transition="scale-transition" v-if="menus['utilerias'] && !esSucursal">
            <template v-slot:activator="{ attrs, on }">
                <v-btn :text="$vuetify.breakpoint.mdAndUp" 
                    :icon="!$vuetify.breakpoint.mdAndUp" 
                    v-on="on" :title="!$vuetify.breakpoint.mdAndUp?'Utilerías':''">
                    <v-icon small>mdi-hammer-screwdriver</v-icon>
                    <!-- Mostrar el texto solo si la pantalla es mayor que "xs" (por ejemplo) -->
                    <span class="caption" v-if="$vuetify.breakpoint.mdAndUp">Utilerías</span>
                </v-btn>
            </template>
            <v-list :tile="false" nav dense width="170">
                <app-bar-item to="/utilerias/configuracion" @click.shift="openNew('/utilerias/configuracion')" v-if="verificaPermiso('t.utilerias.configuracion')">
                    <v-icon>mdi-circle-small</v-icon>Configuración
                </app-bar-item>

                <app-bar-item to="/utilerias/usuarios" @click.shift="openNew('/utilerias/usuarios')" v-if="verificaPermiso('t.utilerias.usuarios')">
                    <v-icon>mdi-circle-small</v-icon>Usuarios
                </app-bar-item>

                <app-bar-item to="/utilerias/perfiles" @click.shift="openNew('/utilerias/perfiles')" v-if="verificaPermiso('t.utilerias.perfiles')">
                    <v-icon>mdi-circle-small</v-icon>Perfiles
                </app-bar-item>

                <app-bar-item to="/utilerias/supervisores" @click.shift="openNew('/utilerias/supervisores')" v-if="verificaPermiso('t.utilerias.supervisores')">
                    <v-icon>mdi-circle-small</v-icon>Supervisores
                </app-bar-item>

                <v-divider />
                <app-bar-item to="/utilerias/sucursales" @click.shift="openNew('/utilerias/sucursales')" v-if="verificaPermiso('t.utilerias.sucursales')">
                    <v-icon>mdi-circle-small</v-icon>Sucursales
                </app-bar-item>
                

                <v-divider />
                <app-bar-item to="/utilerias/tipos_precio" @click.shift="openNew('/utilerias/tipos_precio')" v-if="verificaPermiso('t.utilerias.tipos.precio')">
                    <v-icon>mdi-circle-small</v-icon>Tipos de Precios
                </app-bar-item>
                <app-bar-item to="/utilerias/formaspago" @click.shift="openNew('/utilerias/formaspago')" v-if="verificaPermiso('t.utilerias.formas.pago')">
                    <v-icon>mdi-circle-small</v-icon>Formas de Pago
                </app-bar-item>
                <app-bar-item to="/utilerias/impuestos" @click.shift="openNew('/utilerias/impuestos')" v-if="verificaPermiso('t.utilerias.impuestos')">
                    <v-icon>mdi-circle-small</v-icon>Impuestos
                </app-bar-item>                


            </v-list>
        </v-menu>
        <!-- Catalogos -->
        <v-menu text nav bottom offset-y origin="center center" transition="scale-transition" v-if="menus['catalogos'] && !esSucursal">
            <template v-slot:activator="{ attrs, on }">
                <v-btn :text="$vuetify.breakpoint.mdAndUp" 
                    :icon="!$vuetify.breakpoint.mdAndUp"  
                    v-on="on" :title="!$vuetify.breakpoint.mdAndUp?'Catálogos':''">
                    <v-icon small>mdi-ballot-outline</v-icon>
                    <!-- Mostrar el texto solo si la pantalla es mayor que "xs" (por ejemplo) -->
                    <span class="caption" v-if="$vuetify.breakpoint.mdAndUp">Catálogos</span>
                </v-btn>
            </template>
            <v-list :tile="false" nav dense width="190">
                <app-bar-item to="/catalogos/articulos" @click.shift="openNew('/catalogos/articulos')" v-if="verificaPermiso('t.catalogos.articulos')">
                    <v-icon>mdi-circle-small</v-icon>Artículos
                </app-bar-item>

                <app-bar-item to="/catalogos/descripciones" @click.shift="openNew('/catalogos/descripciones')" v-if="verificaPermiso('t.catalogos.marcas')">
                    <v-icon>mdi-circle-small</v-icon>Descripciones
                </app-bar-item>

                <app-bar-item to="/catalogos/marcas" @click.shift="openNew('/catalogos/marcas')" v-if="verificaPermiso('t.catalogos.marcas')">
                    <v-icon>mdi-circle-small</v-icon>Marcas/Modelos
                </app-bar-item>

                <app-bar-item to="/catalogos/categorias" @click.shift="openNew('/catalogos/categorias')" v-if="verificaPermiso('t.catalogos.categorias')">
                    <v-icon>mdi-circle-small</v-icon>Categorías
                </app-bar-item>

                <app-bar-item to="/catalogos/proveedores" @click.shift="openNew('/catalogos/proveedores')" v-if="verificaPermiso('t.catalogos.proveedores')">
                    <v-icon>mdi-circle-small</v-icon>Proveedores
                </app-bar-item>

                <app-bar-item to="/catalogos/clientes" @click.shift="openNew('/catalogos/clientes')" v-if="verificaPermiso('t.catalogos.clientes')">
                    <v-icon>mdi-circle-small</v-icon>Clientes
                </app-bar-item>

                <app-bar-item to="/catalogos/empleados" @click.shift="openNew('/catalogos/empleados')" v-if="verificaPermiso('t.catalogos.empleados')">
                    <v-icon>mdi-circle-small</v-icon>Empleados
                </app-bar-item>

                <app-bar-item to="/catalogos/sat" @click.shift="openNew('/catalogos/sat')" v-if="verificaPermiso('t.catalogos.sat')">
                    <v-icon>mdi-circle-small</v-icon>Catálogos SAT
                </app-bar-item>
                
                <!--app-bar-item to="/catalogos/ciudades" @click.shift="openNew('/catalogos/ciudades')" v-if="verificaPermiso('t.catalogos.ciudades')">
                    <v-icon>mdi-circle-small</v-icon>Ciudades
                </app-bar-item-->
            </v-list>
        </v-menu>
        <!-- TRASPASOS -->
        <v-menu text nav bottom offset-y origin="center center" transition="scale-transition" v-if="menus['sucursal'] && esSucursal">
            <template v-slot:activator="{ attrs, on }">
                <v-btn :text="$vuetify.breakpoint.mdAndUp" 
                    :icon="!$vuetify.breakpoint.mdAndUp"  
                    v-on="on" :title="!$vuetify.breakpoint.mdAndUp?'Traspasos':''">
                    <v-icon small>mdi-clipboard-flow</v-icon>                    
                    <span class="caption" v-if="$vuetify.breakpoint.mdAndUp">Traspasos</span>
                </v-btn>                
            </template>
            <v-list :tile="false" nav dense width="190">

                <app-bar-item to="/inventario/traspasos" @click.shift="openNew('/inventario/traspasos')" v-if="verificaPermiso('s.inventarios.envio')">
                    <v-icon>mdi-circle-small</v-icon>Generar Traspaso
                </app-bar-item>
                <app-bar-item to="/inventario/traspasos_recibidos" @click.shift="openNew('/inventario/traspasos_recibidos')" v-if="verificaPermiso('s.inventarios.traspasos')">
                    <v-icon>mdi-circle-small</v-icon>Traspasos Recibidos
                </app-bar-item>
                <app-bar-item to="/inventario/salida_traspaso" @click.shift="openNew('/inventario/salida_traspaso')" v-if="verificaPermiso('s.inventarios.salidas')">
                    <v-icon>mdi-circle-small</v-icon>Salidas de Traspaso
                </app-bar-item>
                <app-bar-item to="/inventario/entrada_traspaso" @click.shift="openNew('/inventario/entrada_traspaso')" v-if="verificaPermiso('s.inventarios.entradas')">
                    <v-icon>mdi-circle-small</v-icon>Recibir Traspaso
                </app-bar-item>                
            </v-list>
        </v-menu>

        <!-- Revisión de Mercancía -->
        <v-menu text nav bottom offset-y origin="center center" transition="scale-transition" v-if="menus['sucursal'] && esSucursal">
            <template v-slot:activator="{ attrs, on }">
                <v-btn :text="$vuetify.breakpoint.mdAndUp" 
                    :icon="!$vuetify.breakpoint.mdAndUp"  v-on="on" :title="!$vuetify.breakpoint.mdAndUp?'Revisión de Mercancía':''">
                    <v-icon small>mdi-file-check</v-icon>                    
                    <span class="caption" v-if="$vuetify.breakpoint.mdAndUp">Revisión de Mercancía</span>
                </v-btn>
            </template>
            <v-list :tile="false" nav dense width="190">

                <app-bar-item to="/inventario/salidas" @click.shift="openNew('/inventario/salidas')" v-if="verificaPermiso('s.inventarios.salidas')">
                    <v-icon>mdi-circle-small</v-icon>Salidas de Mercancía
                </app-bar-item>
                <app-bar-item to="/inventario/entradas" @click.shift="openNew('/inventario/entradas')" v-if="verificaPermiso('s.inventarios.entradas')">
                    <v-icon>mdi-circle-small</v-icon>Entradas de Mercancía
                </app-bar-item>               
            </v-list>
        </v-menu>

        <!-- Administracion INVENTARIOS SUCURSAL -->
        <v-menu text nav bottom offset-y origin="center center" transition="scale-transition" v-if="menus['sucursal'] && esSucursal">
            <template v-slot:activator="{ attrs, on }">
                <v-btn :text="$vuetify.breakpoint.mdAndUp" 
                    :icon="!$vuetify.breakpoint.mdAndUp"  v-on="on" :title="!$vuetify.breakpoint.mdAndUp?'Inventario':''">
                    <v-icon small>mdi-counter</v-icon>                    
                    <span class="caption" v-if="$vuetify.breakpoint.mdAndUp">Inventario</span>
                </v-btn>                
            </template>
            <v-list :tile="false" nav dense width="190">

                <app-bar-item to="/inventario/cargar" @click.shift="openNew('/inventario/cargar')" v-if="verificaPermiso('s.inventarios.cargar')">
                    <v-icon>mdi-circle-small</v-icon>Cargar Inventario
                </app-bar-item>
                <app-bar-item to="/inventario/actual" @click.shift="openNew('/inventario/actual')" v-if="verificaPermiso('s.inventarios.actual')">
                    <v-icon>mdi-circle-small</v-icon>Actual
                </app-bar-item>
                <app-bar-item to="/inventario/historico" @click.shift="openNew('/inventario/historico')" v-if="verificaPermiso('s.inventarios.historico')">
                    <v-icon>mdi-circle-small</v-icon>Historico
                </app-bar-item>
                <v-divider />
                <app-bar-item to="/inventario/ajustes" @click.shift="openNew('/inventario/ajustes')" v-if="verificaPermiso('s.inventarios.ajustes')">
                    <v-icon>mdi-circle-small</v-icon>Ajustes
                </app-bar-item>
                <app-bar-item to="/inventario/conteo" @click.shift="openNew('/inventario/conteo')" v-if="verificaPermiso('s.inventarios.conteo')">
                    <v-icon>mdi-circle-small</v-icon>Conteo
                </app-bar-item>
                <app-bar-item to="/inventario/stock" @click.shift="openNew('/inventario/stock')" v-if="verificaPermiso('s.inventarios.stock')">
                    <v-icon>mdi-circle-small</v-icon>Stock de Artículos
                </app-bar-item>
                <app-bar-item to="/inventario/desgranar" @click.shift="openNew('/inventario/desgranar')" v-if="verificaPermiso('s.inventarios.desgranar')">
                    <v-icon>mdi-circle-small</v-icon>Desgranar
                </app-bar-item>

                <v-divider />
                <app-bar-item to="/inventario/merma" @click.shift="openNew('/inventario/merma')" v-if="verificaPermiso('s.inventarios.retazo')">
                    <v-icon>mdi-circle-small</v-icon>Captura de Retazo, Merma y Sobrante
                </app-bar-item>
                <app-bar-item to="/inventario/merma_forrajes" @click.shift="openNew('/inventario/merma_forrajes')" v-if="(verificaPermiso('s.inventarios.mermaforrajes') && esForrajes)">
                    <v-icon>mdi-circle-small</v-icon>Captura Forrajes Merma y Sobrante
                </app-bar-item>

            </v-list>
        </v-menu>
        <!-- Inventarios -->
        <v-menu text nav bottom offset-y origin="center center" transition="scale-transition" v-if="menus['inventario'] && !esSucursal">
            <template v-slot:activator="{ attrs, on }">
                <v-btn :text="$vuetify.breakpoint.mdAndUp" 
                    :icon="!$vuetify.breakpoint.mdAndUp"  v-on="on" :title="!$vuetify.breakpoint.mdAndUp?'Inventarios':''">
                    <v-icon small>mdi-table</v-icon>                    
                    <span class="caption" v-if="$vuetify.breakpoint.mdAndUp">Inventarios</span>
                </v-btn>                
            </template>
            <v-list :tile="false" nav dense width="200">
                <app-bar-item to="/inventario/equivalencias" @click.shift="openNew('/inventario/equivalencias')" v-if="verificaPermiso('t.inventarios.equivalencias')">
                    <v-icon>mdi-circle-small</v-icon>Artículos Equivalencias
                </app-bar-item>
                
                <app-bar-item to="/inventario/comprometido" @click.shift="openNew('/inventario/comprometido')" v-if="verificaPermiso('t.inventarios.comprometido')">
                    <v-icon>mdi-circle-small</v-icon>Inventario Comprometido
                </app-bar-item>
                <app-bar-item to="/inventario/ajustes_cremeria" @click.shift="openNew('/inventario/ajustes_cremeria')" v-if="verificaPermiso('t.inventarios.cremeria')">
                    <v-icon>mdi-circle-small</v-icon>Ajustes de Cremería
                </app-bar-item>
                <app-bar-item to="/inventario/ajustes_forrajes" @click.shift="openNew('/inventario/ajustes_forrajes')" v-if="verificaPermiso('t.inventarios.mermaforrajes')">
                    <v-icon>mdi-circle-small</v-icon>Ajustes de Forrajes
                </app-bar-item>
                <v-divider />
                
                <app-bar-item to="/reportes/inventario" @click.shift="openNew('/reportes/inventario')" v-if="verificaPermiso('t.inventarios.inventario')">
                    <v-icon>mdi-circle-small</v-icon>Inventario
                </app-bar-item>

                <app-bar-item to="/reportes/stock_general" @click.shift="openNew('/reportes/stock_general')" v-if="verificaPermiso('t.inventarios.stock.general')">
                    <v-icon>mdi-circle-small</v-icon>Stock General
                </app-bar-item>

                <app-bar-item to="/reportes/stock_proveedores" @click.shift="openNew('/reportes/stock_proveedores')" v-if="verificaPermiso('t.inventarios.stock.proveedores')">
                    <v-icon>mdi-circle-small</v-icon>Stock Proveedores
                </app-bar-item>

                <app-bar-item to="/reportes/ajustes_inventario" @click.shift="openNew('/reportes/ajustes_inventario')" v-if="verificaPermiso('t.inventarios.ajustes.inventario')">
                    <v-icon>mdi-circle-small</v-icon>Ajustes de Inventario
                </app-bar-item>

                <app-bar-item to="/reportes/reporte_caducidad" @click.shift="openNew('/reportes/reporte_caducidad')" v-if="verificaPermiso('t.inventarios.caducidades')">
                    <v-icon>mdi-circle-small</v-icon>Caducidades
                </app-bar-item>

                <app-bar-item to="/reportes/movimientos_inventario" @click.shift="openNew('/reportes/movimientos_inventario')" v-if="verificaPermiso('t.inventarios.inventario')">
                    <v-icon>mdi-circle-small</v-icon>Entradas/Salidas de Inventario
                </app-bar-item>

            </v-list>
        </v-menu>

        <!-- Precios -->
        <v-menu text nav bottom offset-y origin="center center" transition="scale-transition" v-if="menus['precios'] && !esSucursal">
            <template v-slot:activator="{ attrs, on }">
                <v-btn :text="$vuetify.breakpoint.mdAndUp" 
                    :icon="!$vuetify.breakpoint.mdAndUp"  v-on="on" :title="!$vuetify.breakpoint.mdAndUp?'Precios':''">
                    <v-icon small>attach_money</v-icon>                    
                    <span class="caption" v-if="$vuetify.breakpoint.mdAndUp">Precios</span>
                </v-btn>                
            </template>
            <v-list :tile="false" nav dense width="200">
                
                <app-bar-item to="/precios/ajustar" @click.shift="openNew('/precios/ajustar')" v-if="verificaPermiso('t.precios.ajustar.precios')">
                    <v-icon>mdi-circle-small</v-icon>Ajustar Precios Masivo
                </app-bar-item>

                <app-bar-item to="/precios/ajustar_articulo" @click.shift="openNew('/precios/ajustar_articulo')" v-if="verificaPermiso('t.precios.ajustar.articulo')">
                    <v-icon>mdi-circle-small</v-icon>Ajustar Precio Artículo
                </app-bar-item>

                <app-bar-item to="/precios/plantillas" @click.shift="openNew('/precios/plantillas')" v-if="verificaPermiso('t.precios.plantillas')">
                    <v-icon>mdi-circle-small</v-icon>Plantillas Precios
                </app-bar-item>

                <app-bar-item to="/precios/lista_precios" @click.shift="openNew('/precios/lista_precios')" v-if="verificaPermiso('t.precios.lista.precios')">
                    <v-icon>mdi-circle-small</v-icon>Lista de Precios
                </app-bar-item>

                <app-bar-item to="/precios/solicitud" @click.shift="openNew('/precios/solicitud')" v-if="verificaPermiso('t.precios.etiquetas')">
                    <v-icon>mdi-circle-small</v-icon>Solicitud de Etiquetas
                </app-bar-item>

            </v-list>
        </v-menu>
       
        <!-- Compras -->
        <v-menu text nav bottom offset-y origin="center center" transition="scale-transition" v-if="menus['compras'] && !esSucursal">
            <template v-slot:activator="{ attrs, on }">
                <v-btn :text="$vuetify.breakpoint.mdAndUp" 
                    :icon="!$vuetify.breakpoint.mdAndUp"  v-on="on" :title="!$vuetify.breakpoint.mdAndUp?'Compras':''">
                    <v-icon small>mdi-cart</v-icon>                    
                    <span class="caption" v-if="$vuetify.breakpoint.mdAndUp">Compras</span>
                </v-btn>                
            </template>
            <v-list :tile="false" nav dense width="180">

                <app-bar-item to="/compras/ordenes" @click.shift="openNew('/compras/ordenes')" v-if="verificaPermiso('t.compras.ordenes')">
                    <v-icon>mdi-circle-small</v-icon>Ordenes de Compra
                </app-bar-item>

                <app-bar-item to="/compras/indicadores" @click.shift="openNew('/compras/indicadores')" v-if="verificaPermiso('t.compras.ordenes')">
                    <v-icon>mdi-circle-small</v-icon>Indicadores Compras
                </app-bar-item>

                <app-bar-item to="/compras/subastas" @click.shift="openNew('/compras/subastas')" v-if="verificaPermiso('t.compras.subastas')">
                    <v-icon>mdi-circle-small</v-icon>Subastas
                </app-bar-item>

                <app-bar-item to="/compras/por_pagar" @click.shift="openNew('/compras/por_pagar')" v-if="verificaPermiso('t.compras.credito')">
                    <v-icon>mdi-circle-small</v-icon>Compras a Crédito
                </app-bar-item>

                <app-bar-item to="/compras/sello" @click.shift="openNew('/compras/sello')" v-if="verificaPermiso('t.compras.sellos')">
                    <v-icon>mdi-circle-small</v-icon>Sello Facturas
                </app-bar-item>

                <v-divider />

                <app-bar-item to="/reportes/compras" @click.shift="openNew('/reportes/compras')" v-if="verificaPermiso('t.compras.compras')">
                    <v-icon>mdi-circle-small</v-icon>Compras
                </app-bar-item>
                <app-bar-item to="/reportes/compras_articulo" @click.shift="openNew('/reportes/compras_articulo')" v-if="verificaPermiso('t.compras.articulos')">
                    <v-icon>mdi-circle-small</v-icon>Compras por Articulo
                </app-bar-item>
                <app-bar-item to="/comprasprecios" @click.shift="openNew('/comprasprecios')" v-if="verificaPermiso('t.compras.precios')">
                    <v-icon>mdi-circle-small</v-icon>Revisar Precios
                </app-bar-item>

            </v-list>
        </v-menu>
        <!-- Reportes Ventas -->
        <v-menu text nav bottom offset-y origin="center center" transition="scale-transition"
            v-if="menus['ventas'] && !esSucursal">
            <template v-slot:activator="{ attrs, on }">
                <v-btn :text="$vuetify.breakpoint.mdAndUp" 
                    :icon="!$vuetify.breakpoint.mdAndUp"  v-on="on" :title="!$vuetify.breakpoint.mdAndUp?'Ventas':''">
                    <v-icon small>mdi-cash</v-icon>                    
                    <span class="caption" v-if="$vuetify.breakpoint.mdAndUp">Ventas</span>
                </v-btn>                
            </template>
            <v-list :tile="false" nav dense width="220">

                <app-bar-item to="/consulta/ventas" @click.shift="openNew('/consulta/ventas')" v-if="verificaPermiso('t.ventas.consulta')">
                    <v-icon>mdi-circle-small</v-icon>Consultar Ventas
                </app-bar-item>

                <app-bar-item to="/consulta/caratulas_folios" @click.shift="openNew('/consulta/caratulas_folios')" v-if="verificaPermiso('t.ventas.folios')">
                    <v-icon>mdi-circle-small</v-icon>Caratulas folios
                </app-bar-item>

                <app-bar-item to="/ventas/modificar_cobro" @click.shift="openNew('/ventas/modificar_cobro')" v-if="verificaPermiso('t.ventas.tipos.cobro')">
                    <v-icon>mdi-circle-small</v-icon>Modificar Tipo Cobro
                </app-bar-item>

                
                <!-- PEDIDOS EN LINEA HASTA NUEVO AVISO-->
                <!--app-bar-item to="/consulta/pedidos_online" @click.shift="openNew('/consulta/pedidos_online')" v-if="true">
                    <v-icon>mdi-circle-small</v-icon>Pedidos En Línea
                </app-bar-item-->

                <v-divider />

                <app-bar-item to="/reportes/ventas_totales" @click.shift="openNew('/reportes/ventas_totales')" v-if="verificaPermiso('t.ventas.totales')">
                    <v-icon>mdi-circle-small</v-icon>Ventas Totales
                </app-bar-item>

                <app-bar-item to="/reportes/ventas_articulo" @click.shift="openNew('/reportes/ventas_articulo')" v-if="verificaPermiso('t.ventas.articulo')">
                    <v-icon>mdi-circle-small</v-icon>Ventas Articulo
                </app-bar-item>

                <app-bar-item to="/reportes/ventas_clientes" @click.shift="openNew('/reportes/ventas_clientes')" v-if="verificaPermiso('t.ventas.clientes')">
                    <v-icon>mdi-circle-small</v-icon>Ventas a Clientes
                </app-bar-item>

                <app-bar-item to="/reportes/ventas_empleados" @click.shift="openNew('/reportes/ventas_empleados')" v-if="verificaPermiso('t.ventas.empleado')">
                    <v-icon>mdi-circle-small</v-icon>Ventas por Empleado
                </app-bar-item>

                <app-bar-item to="/reportes/ventas_observaciones" @click.shift="openNew('/reportes/ventas_observaciones')" v-if="verificaPermiso('t.ventas.observaciones')">
                    <v-icon>mdi-circle-small</v-icon>Ventas con Observaciones
                </app-bar-item>

                <app-bar-item to="/reportes/ventas_devolucion" @click.shift="openNew('/reportes/ventas_devolucion')" v-if="verificaPermiso('t.ventas.devoluciones')">
                    <v-icon>mdi-circle-small</v-icon>Ventas con Devolución
                </app-bar-item>

                <app-bar-item to="/reportes/ventas_diferencias_salida" @click.shift="openNew('/reportes/ventas_diferencias_salida')" v-if="verificaPermiso('t.ventas.diferencias.salidas')">
                    <v-icon>mdi-circle-small</v-icon>Ventas con Diferencias en Salida de Mercancía
                </app-bar-item>

                <app-bar-item to="/reportes/ventas_diferencias_devolucion" @click.shift="openNew('/reportes/ventas_diferencias_devolucion')" v-if="verificaPermiso('t.ventas.diferencias.devoclucion')">
                    <v-icon>mdi-circle-small</v-icon>Ventas con Diferencias en Devolución de Mercancía
                </app-bar-item>

                <app-bar-item to="/reportes/utilidades_ventas" @click.shift="openNew('/reportes/utilidades_ventas')" v-if="verificaPermiso('t.ventas.utilidad')">
                    <v-icon>mdi-circle-small</v-icon>Utilidades por Venta
                </app-bar-item>

                <app-bar-item to="/reportes/utilidades_articulo" @click.shift="openNew('/reportes/utilidades_articulo')" v-if="verificaPermiso('t.ventas.utilidad.articulo')">
                    <v-icon>mdi-circle-small</v-icon>Utilidades por Artículo
                </app-bar-item>                
                
            </v-list>
        </v-menu>

        <!-- Cortes -->
        <v-menu text nav bottom offset-y origin="center center" transition="scale-transition"
            v-if="menus['cortes'] && !esSucursal">
            <template v-slot:activator="{ attrs, on }">
                <v-btn :text="$vuetify.breakpoint.mdAndUp" 
                    :icon="!$vuetify.breakpoint.mdAndUp"  v-on="on" :title="!$vuetify.breakpoint.mdAndUp?'Cortes':''">
                    <v-icon small>mdi-content-cut</v-icon>                    
                    <span class="caption" v-if="$vuetify.breakpoint.mdAndUp">Cortes</span>
                </v-btn>
            </template>
            <v-list :tile="false" nav dense width="180">
                
                <app-bar-item to="/cortes/resumen_ventas_empleado" @click.shift="openNew('/cortes/resumen_ventas_empleado')" v-if="verificaPermiso('t.cortes.empleado.resumen')">
                    <v-icon>mdi-circle-small</v-icon>Resumen Ventas Empleado
                </app-bar-item>

                <app-bar-item to="/cortes/corte_general" @click.shift="openNew('/cortes/corte_general')" v-if="verificaPermiso('t.cortes.empleado.resumen')">
                    <v-icon>mdi-circle-small</v-icon>Cortes Generales
                </app-bar-item>

                <app-bar-item to="/cortes/corte_total" @click.shift="openNew('/cortes/corte_total')" v-if="verificaPermiso('t.cortes.cortes')">
                    <v-icon>mdi-circle-small</v-icon>Corte General Total
                </app-bar-item>

                <app-bar-item to="/cortes/cortes_caja" @click.shift="openNew('/cortes/cortes_caja')" v-if="verificaPermiso('t.cortes.cortes')">
                    <v-icon>mdi-circle-small</v-icon>Cortes de Caja
                </app-bar-item>

                <app-bar-item to="/cortes/sepsa" @click.shift="openNew('/cortes/sepsa')" v-if="verificaPermiso('t.cortes.cortes')">
                    <v-icon>mdi-circle-small</v-icon>SEPSA
                </app-bar-item>

            </v-list>
        </v-menu>

        <!-- Facturas -->
        <v-menu text nav bottom offset-y origin="center center" transition="scale-transition" v-if="menus['facturas'] && !esSucursal">
            <template v-slot:activator="{ attrs, on }">
                <v-btn :text="$vuetify.breakpoint.mdAndUp" 
                    :icon="!$vuetify.breakpoint.mdAndUp"  v-on="on" :title="!$vuetify.breakpoint.mdAndUp?'Facturas':''">
                    <v-icon small>mdi-qrcode</v-icon>                    
                    <span class="caption" v-if="$vuetify.breakpoint.mdAndUp">Facturas</span>
                </v-btn>                
            </template>
            <v-list :tile="false" nav dense width="180">
                <app-bar-item to="/facturacion" @click.shift="openNew('/facturacion')" target="_blank" v-if="verificaPermiso('t.facturas.crear')">
                    <v-icon>mdi-circle-small</v-icon>Crear Factura
                </app-bar-item>

                <app-bar-item to="/facturas/facturas_globales" @click.shift="openNew('/facturas/facturas_globales')" v-if="verificaPermiso('t.facturas.globales')">
                    <v-icon>mdi-circle-small</v-icon>Facturas Globales
                </app-bar-item>

                <app-bar-item to="/reportes/facturas" @click.shift="openNew('/reportes/facturas')" v-if="verificaPermiso('t.facturas.consultar')">
                    <v-icon>mdi-circle-small</v-icon>Consultar Facturas
                </app-bar-item>
                <v-divider />
                <app-bar-item to="/reportes/utilidades_factura" @click.shift="openNew('/reportes/utilidades_factura')" v-if="verificaPermiso('t.facturas.utilidades')">
                    <v-icon>mdi-circle-small</v-icon>Utilidades por Factura
                </app-bar-item>

                <app-bar-item to="/reportes/reporte_facturas" @click.shift="openNew('/reportes/reporte_facturas')" v-if="verificaPermiso('t.facturas.reporte')">
                    <v-icon>mdi-circle-small</v-icon>Reporte de Facturas
                </app-bar-item>

                <app-bar-item to="/reportes/reporte_impuestos" @click.shift="openNew('/reportes/reporte_impuestos')" v-if="verificaPermiso('t.facturas.impuestos')">
                    <v-icon>mdi-circle-small</v-icon>Reporte de Impuestos
                </app-bar-item>

                <app-bar-item to="/reportes/excentas_gravadas" @click.shift="openNew('/reportes/excentas_gravadas')" v-if="verificaPermiso('t.facturas.excentas')">
                    <v-icon>mdi-circle-small</v-icon>Ventas Excentas y Gravadas
                </app-bar-item>

                <v-divider />

                <app-bar-item to="/reportes/diferencias_ventas" @click.shift="openNew('/reportes/diferencias_ventas')" v-if="verificaPermiso('t.facturas.consultar')">
                    <v-icon>mdi-circle-small</v-icon>Diferencias en Facturación
                </app-bar-item>

            </v-list>
        </v-menu>
        
        <!-- Cotizaciones -->
        <v-menu text nav bottom offset-y origin="center center" transition="scale-transition"
            v-if="menus['cotizaciones'] && !esSucursal">
            <template v-slot:activator="{ attrs, on }">
                <v-btn :text="$vuetify.breakpoint.mdAndUp" 
                    :icon="!$vuetify.breakpoint.mdAndUp"  v-on="on" :title="!$vuetify.breakpoint.mdAndUp?'Cotizaciones':''">
                    <v-icon small>ballot</v-icon>                    
                    <span class="caption" v-if="$vuetify.breakpoint.mdAndUp">Cotizaciones</span>
                </v-btn>                
            </template>
            <v-list :tile="false" nav dense width="180">
                <app-bar-item to="/cotizacion" @click.shift="openNew('/cotizacion')" v-if="verificaPermiso('t.cotizaciones.crear')">
                    <v-icon>mdi-circle-small</v-icon>Crear Cotización
                </app-bar-item>
            </v-list>
        </v-menu>

        <!-- Verificador -->
        <v-menu text nav bottom offset-y origin="center center" transition="scale-transition">
            <template v-slot:activator="{ attrs, on }">
                <v-btn :text="$vuetify.breakpoint.mdAndUp" 
                    :icon="!$vuetify.breakpoint.mdAndUp"  v-on="on" @click="openNew('/verificador')" @click.shift="openNew('/verificador')" :title="!$vuetify.breakpoint.mdAndUp?'Verificador':''">
                    <v-icon small>mdi-barcode</v-icon>                    
                    <span class="caption" v-if="$vuetify.breakpoint.mdAndUp">Verificador</span>
                </v-btn>                
            </template>            
        </v-menu>

         <!-- Manual de Usuario -->
        <v-menu bottom offset-y origin="center center" nav transition="scale-transition" v-if="$vuetify.breakpoint.mdAndUp">
            <template v-slot:activator="{ attrs, on }">
                <v-btn :text="$vuetify.breakpoint.mdAndUp" 
                    :icon="!$vuetify.breakpoint.mdAndUp"  v-on="on"  title="Manual de Usuario" v-tippy>
                    <v-icon small>mdi-help-circle-outline</v-icon>
                </v-btn>
            </template>
            <v-list :tile="false" nav>
                <app-bar-item @click.native="descarga_manual('sistema_web')">
                    <v-icon>mdi-circle-small</v-icon>Manual Sistema WEB
                </app-bar-item>
                <app-bar-item @click.native="descarga_manual('punto_venta')">
                    <v-icon>mdi-circle-small</v-icon>Manual Punto de Venta
                </app-bar-item>
            </v-list>
        </v-menu>

        <v-spacer />

        <!-- Notificaciones -->
<!--         <v-menu bottom left offset-y origin="bottom left" width="500" transition="scale-transition">
            <template v-slot:activator="{ attrs, on }">
                <v-btn dark small text v-bind="attrs" v-on="on">
                    <v-badge color="red" :offset-x="20" :offset-y="5">
                        <template v-slot:badge>
                            <span class="caption">{{
                  notificationsNumber > 99 ? "99+" : notificationsNumber
                }}</span>
                        </template>
                        <v-icon>mdi-bell</v-icon>
                    </v-badge>
                </v-btn>
            </template>

            <notification-list :key="this.$store.state.datetime"></notification-list>
        </v-menu>
 -->
        <!-- Avatar Usuarios -->
        <v-menu text nav bottom left min-width="200" offset-y origin="top right" transition="scale-transition">
            <template v-slot:activator="{ attrs, on }">
                <v-btn min-width="0" icon text v-bind="attrs" v-on="on">
                    <v-avatar size="32px" :key="avatar">
                        <v-icon medium>mdi-account</v-icon>
                    </v-avatar>
                </v-btn>
            </template>

            <v-list :tile="false" text nav dense>
                <template>
                    <app-bar-item to="/usuarios/change">
                        <v-icon>mdi-circle-small</v-icon>Cambiar Contraseña
                    </app-bar-item>
                    <v-divider />
                    <app-bar-item @click.native="cerrarSesion()">
                        <v-icon>mdi-circle-small</v-icon>Cerrar Sesión
                    </app-bar-item>                                        
                </template>
            </v-list>
        </v-menu>
        
    </v-app-bar>
    <!-- <v-idle
            @idle="cerrarSesionInactividad"
            :wait="60"
            :duration="6000" /> -->
</div>
</template>

<script>
// Components
import {
    VHover,
    VListItem
} from "vuetify/lib";

// Utilities
import {
    mapState,
    mapMutations
} from "vuex";
import NotificationList from "@/components/list/NotificationList";
import Util from "@/util";

import resize from "vue-resize-directive";

export default {
    name: "DashboardCoreAppBar",

    directives: {
        resize,
    },
    created() {
       /*  this.getNotificaciones();
        this.$root.$on("notificationsNumber", (val) => {
            this.notificationsNumber = val;
        }); */
        this.menus["cortes"] =
            this.verificaPermiso("t.cortes.empleado.resumen") ||
            this.verificaPermiso("t.cortes.cortes")        
            ;
        this.menus["precios"] =
            this.verificaPermiso("t.precios.etiquetas") ||
            this.verificaPermiso("t.precios.ajustar.precios") ||            
            this.verificaPermiso("t.precios.lista.precios") ||
            this.verificaPermiso("t.precios.plantillas")        
            ;
        this.menus["utilerias"] =
            this.verificaPermiso("t.utilerias.configuracion") ||
            this.verificaPermiso("t.utilerias.usuarios") ||
            this.verificaPermiso("t.utilerias.perfiles") ||
            this.verificaPermiso("t.utilerias.supervisores") ||
            this.verificaPermiso("t.utilerias.sucursales") ||            
            this.verificaPermiso("t.utilerias.tipos.precio") ||
            this.verificaPermiso("t.utilerias.formas.pago") ||
            this.verificaPermiso("t.utilerias.impuestos")            
            ;
        this.menus["catalogos"] =
            this.verificaPermiso("t.catalogos.articulos") ||            
            this.verificaPermiso("t.catalogos.marcas") ||
            this.verificaPermiso("t.catalogos.categorias") ||
            this.verificaPermiso("t.catalogos.proveedores") ||
            this.verificaPermiso("t.catalogos.clientes") ||
            this.verificaPermiso("t.catalogos.empleados") ||            
            this.verificaPermiso("t.catalogos.sat") ||
            this.verificaPermiso("t.catalogos.ciudades");
        this.menus["inventario"] =
            this.verificaPermiso("t.inventarios.equivalencias") ||
            this.verificaPermiso("t.inventarios.comprometido") ||
            this.verificaPermiso("t.inventarios.cremeria") ||    
            this.verificaPermiso("t.inventarios.mermaforrajes") ||
            this.verificaPermiso("t.inventarios.inventario") ||
            this.verificaPermiso("t.inventarios.stock.general") ||
            this.verificaPermiso("t.inventarios.stock.proveedores") ||
            this.verificaPermiso("t.inventarios.ajustes.inventario") ||
            this.verificaPermiso("t.inventarios.caducidades") ;
        this.menus["compras"] =
            this.verificaPermiso("t.compras.ordenes") ||
            this.verificaPermiso("t.compras.subastas") ||
            this.verificaPermiso("t.compras.credito") ||
            this.verificaPermiso("t.compras.sellos") ||
            this.verificaPermiso("t.compras.compras") ||
            this.verificaPermiso("t.compras.articulos") ||
            this.verificaPermiso("t.compras.precios");
        this.menus["ventas"] =
            this.verificaPermiso("t.ventas.consulta") ||
            this.verificaPermiso("t.ventas.folios") ||
            this.verificaPermiso("t.ventas.tipos.cobro") ||            
            this.verificaPermiso("t.ventas.totales") ||
            this.verificaPermiso("t.ventas.articulo") ||
            this.verificaPermiso("t.ventas.clientes") ||
            this.verificaPermiso("t.ventas.empleado") ||
            this.verificaPermiso("t.ventas.observaciones") ||
            this.verificaPermiso("t.ventas.devoluciones") ||
            this.verificaPermiso("t.ventas.diferencias.salidas") ||
            this.verificaPermiso("t.ventas.diferencias.devoclucion");

        this.menus["facturas"] =
            this.verificaPermiso("t.facturas.crear") ||
            this.verificaPermiso("t.facturas.globales") ||
            this.verificaPermiso("t.facturas.consultar") ||
            this.verificaPermiso("t.facturas.utilidades") ||
            this.verificaPermiso("t.facturas.reporte") ||
            this.verificaPermiso("t.facturas.impuestos") ||
            this.verificaPermiso("t.facturas.excentas") ;
        this.menus["cotizaciones"] =
            this.verificaPermiso("t.cotizaciones.crear");
        this.menus["sucursal"] =
            this.verificaPermiso("s.inventarios.envio") ||
            this.verificaPermiso("s.inventarios.traspasos") ||
            this.verificaPermiso("s.inventarios.salidas") ||
            this.verificaPermiso("s.inventarios.entradas") ||
            this.verificaPermiso("s.inventarios.cargar") ||
            this.verificaPermiso("s.inventarios.actual") ||
            this.verificaPermiso("s.inventarios.historico") ||
            this.verificaPermiso("s.inventarios.ajustes") ||
            this.verificaPermiso("s.inventarios.conteo") ||
            this.verificaPermiso("s.inventarios.stock") ||
            this.verificaPermiso("s.inventarios.desgranar") ||
            this.verificaPermiso("s.inventarios.retazo") || 
            this.verificaPermiso("s.inventarios.mermaforrajes");

    },
    components: {
        NotificationList,
        AppBarItem: {
            render(h) {
                return h(VHover, {
                    scopedSlots: {
                        default: ({
                            hover
                        }) => {
                            return h(
                                VListItem, {
                                    attrs: this.$attrs,
                                    class: {
                                        "black--text": !hover,
                                        "white--text secondary elevation-12": hover,
                                    },
                                    props: {
                                        activeClass: "",
                                        dark: hover,
                                        link: true,
                                        ...this.$attrs,
                                    },
                                },
                                this.$slots.default
                            );
                        },
                    },
                });
            },
        },
    },

    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        menus: [],
        fab: false,
        show_loading: false,
        notificationsNumber: 0,
        show: true,
        minifiedLeft: false,
        hiddenLeft: false,
        avatar: "undefined",
        fullWidth: document.documentElement.clientWidth,
        notifications: [],
    }),

    computed: {
        ...mapState(["drawer"]),
        toolbarColor() {
            return this.$vuetify.options.extra.mainNav;
        },
        esSucursal() {
            return this.$local_storage.get("sb_sucursal") != 0;
        },
        esForrajes() {
            var sucursal = this.$local_storage.get("sb_nombre_sucursal","");
            if(sucursal != "" && sucursal.toString().toLowerCase().indexOf("forrajes") != -1){
                return true;
            }
            return false;            
        },
    },

    methods: {
        ...mapMutations({
            setDrawer: "SET_DRAWER",
        }),

        handleFullScreen() {
            Util.toggleFullScreen();
        },

        openNew: function (h) {
            window.open(h, "_blank");
        },

        getNotificaciones: function () {},

        descarga_manual: function(opcion){

            window.dialogLoader.show('Espere un momento por favor..');
            var self = this;
            let data = {
                "selector":{
                    "type":"manuales",
                },                 
            }
            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    if (response.data.docs.length) {
                        
                        for(var k in response.data.docs){
                            if(response.data.docs[k].tipo == opcion){
                                
                                if(response.data.docs[k]._attachments){
                                    for(var key in response.data.docs[k]._attachments){
                                        var name = encodeURIComponent(key);
                                        var a = document.createElement("a");
                                        document.body.appendChild(a);
                                        a.style = "display: none";
                                        a.href = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' +response.data.docs[k]._id+'/'+name+"?rev="+response.data.docs[k]._rev;
                                        a.target = "_blank";
                                        a.click();
                                        a.parentNode.removeChild(a);
                                        break;
                                    }
                                    break;
                                }

                                
                            }
                        }
                    }
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener el registro",
                        footer: ""
                    });
                }).then(()=>{
                    window.dialogLoader.hide();
                });

            
        },
    },
    mounted: async function () {
        this.$root.$on("cambiaravatar", (new_avatar) => {
            this.avatar = new_avatar;
        });
        this.avatar = this.$local_storage.get("sb_avatar");
    },
};
</script>

<style scoped>
.v-menu__content .v-list--nav .v-list-item {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 5px;
    text-decoration: none;
}

.v-sheet button.v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab) {
    padding-left: 10px !important;
}
</style>
